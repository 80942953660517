"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _Code = _interopRequireDefault(require("./components/Code"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _message = require("@/components/dialog/message");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _security = require("@/model/user/security");
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      loginLoading: false,
      // 登录loading
      currentType: 0,
      // 当前选项
      loginData: {
        // 登录数据
        code: ""
      },
      formRules: {
        code: [{
          required: true,
          validator: this.loginValidator,
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`请输入正确的6位验证码`),
          trigger: "blur"
        }]
      },
      accountInfo: {},
      authInfo: {},
      validList: [],
      mobileCounting: false,
      emailCounting: false
    };
  },
  created() {
    if (this.$route.params && this.$route.params.authInfo) {
      let {
        accountInfo,
        authInfo,
        authInfo: {
          open_google,
          open_email,
          open_mobile
        }
      } = this.$route.params;
      this.accountInfo = accountInfo;
      this.authInfo = authInfo;
      if (open_google == 1) {
        this.validList.push({
          key: "0",
          title: this.translateTitle(`谷歌`)
        });
      }
      if (open_email == 1) {
        this.validList.push({
          key: "1",
          title: this.translateTitle(`邮箱`)
        });
      }
      if (open_mobile == 1) {
        this.validList.push({
          key: "2",
          title: this.translateTitle(`手机号`)
        });
      }
      this.currentType = this.validList[0].key;
    } else {
      this.$router.go(-1);
      return;
    }
    document.onkeyup = e => {
      let keycode = document.all ? event.keyCode : e.which;
      // 在登录界面 且 按得键是回车键enter
      if (
      // [
      //   this.routeSplicing(this.language, "login/mobile"),
      //   this.routeSplicing(this.language, "login/email")
      // ].includes(this.$route.path) &&
      ["/login/mobile", "/login/email"].includes(this.$route.path) && keycode === 13) {
        this.onLogin();
      }
    };
  },
  mounted() {
    this.initFocus();
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  watch: {
    loginData: {
      handler(val) {
        if (val.code.length == 6) {
          this.onLogin();
        }
      },
      deep: true
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    initFocus() {
      if (this.currentType == 0) {
        this.$refs.google.onFocus();
      } else if (this.currentType == 1) {
        this.$refs.email.onFocus();
      } else if (this.currentType == 2) {
        this.$refs.mobile.onFocus();
      }
    },
    onSercuity() {
      this.$router.push(this.routeSplicing(this.language, "loginSecurity"));
    },
    onMobileCode() {
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      const data = {
        type: 1,
        token: tempToken
      };
      (0, _security.loginSendSmsCode)(data).then(rs => {
        if (rs.status == 200) {
          this.mobileCounting = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`发送成功`),
            type: "success"
          });
          this.$refs.mobile.onFocus();
        }
      });
    },
    onEmailCode() {
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      const data = {
        type: 31,
        token: tempToken
      };
      (0, _security.loginSendEmailCode)(data).then(rs => {
        if (rs.status == 200) {
          this.emailCounting = true;
          (0, _message.showMessage)({
            message: this.translateTitle(`发送成功`),
            type: "success"
          });
          this.$refs.email.onFocus();
        }
      });
    },
    // 登录校验
    loginValidator(rule, value, callback) {
      let {
        currentType
      } = this;
      let msg = "";
      if (currentType == 0) {
        msg = this.translateTitle(`请输入正确的6位验证码`);
      } else if (currentType == 1) {
        msg = this.translateTitle(`请输入邮箱验证码`);
      } else if (currentType == 2) {
        msg = this.translateTitle(`请输入手机验证码`);
      }
      if (value === "" || !value) {
        callback(new Error(msg));
      } else {
        callback();
      }
    },
    /* 切换tab */
    onTabChange({
      name
    }) {
      this.currentType = name;
      this.$refs.loginForm.resetFields();

      // this.$nextTick(() => {
      //   if (name == 0) {
      //     this.$refs.google.onFocus();
      //   } else if (name == 1) {
      //     this.$refs.email.onFocus();
      //   } else if (name == 2) {
      //     this.$refs.mobile.onFocus();
      //   }
      // });
    },

    /* 获取请求参数 */
    getParams() {
      let {
        currentType
      } = this;
      let params = {
        ...this.loginData
      };
      let tempToken = sessionStorage.getItem("topcredit_exchange_t");
      params.token = tempToken;
      switch (currentType) {
        case "0":
          params.google_code = params.code;
          break;
        case "1":
          params.email_code = params.code;
          break;
        case "2":
          params.mobile_code = params.code;
          break;
      }
      delete params.code;
      return params;
    },
    /* 登录点击 */
    onLogin() {
      let {
        currentType
      } = this;
      this.$refs.loginForm.validate(async valid => {
        if (valid) {
          let result;
          let params = this.getParams();
          this.loginLoading = true;
          if (currentType == 0) {
            result = await Account.googleVerifyLogin(params);
          } else if (currentType == 1) {
            result = await Account.emailVerify(params);
          } else if (currentType == 2) {
            result = await Account.smsVerify(params);
          }
          this.handLoginSuccess(result.data, result.status, result.msg);
          this.loginLoading = false;
        }
      });
    },
    /* 登录成功|失败处理 */
    async handLoginSuccess(data, status, msg) {
      if (status !== 200) {
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
        return;
      }
      (0, _message.showMessage)({
        message: this.translateTitle(`登录成功`),
        type: "success"
      });
      //写入cookie, 邮箱登录 , 手机登录  注册一致
      await Account.writeUserInfo(data, true);
      this.$store.dispatch("user/getUserInfo").then(res => {
        let jump = this.$route.params.jump;
        if (jump) {
          //by TZ bakUrl貌似又有问题了。我这里解决的是bakUrl=true/false的问题。
          location.href = jump;
          return;
        } else {
          this.redirectBasedAutInfo(res.auth);
        }
      });
    },
    // 根据认证信息重定向

    redirectBasedAutInfo(info = {}) {
      let jump = this.$route.fullPath.split("redirect=")[1] || "";
      if (jump) {
        jump = decodeURIComponent(jump);
      } else {
        this.$router.push({
          path: "/"
        });
      }
    }
  },
  components: {
    InputNumber: _InputNumber.default,
    Code: _Code.default
  }
};
exports.default = _default;