"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stationSetting = require("@/assets/js/stationSetting");
var _vuex = require("vuex");
var _loginVerificationInnerBv = _interopRequireDefault(require("@/pages/login/loginVerification-inner-bv.vue"));
var _loginVerificationInner = _interopRequireDefault(require("@/pages/login/loginVerification-inner.vue"));
//
//
//
//
//
//
//
var _default = {
  mixins: [],
  data() {
    return {
      is8V: false,
      defaultPage: false
    };
  },
  components: {
    LoginVerificationInner: _loginVerificationInner.default,
    LoginVerificationInnerBv: _loginVerificationInnerBv.default
  },
  computed: {
    // ...mapGetters({
    //   darkMode: "user/darkMode"
    // })
  },
  created() {
    // this.defaultPage = true;
    if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
  },
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {}
};
exports.default = _default;