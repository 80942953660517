"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetUserInfo = apiGetUserInfo;
exports.bindEmail = bindEmail;
exports.bindGoogleVerify = bindGoogleVerify;
exports.bindMobile = bindMobile;
exports.bingGoogleVerifyStatus = bingGoogleVerifyStatus;
exports.checkIp = checkIp;
exports.closeGoogleVerify = closeGoogleVerify;
exports.getGoogleSecret = getGoogleSecret;
exports.loginSendEmailCode = loginSendEmailCode;
exports.loginSendSmsCode = loginSendSmsCode;
exports.openGoogleVerify = openGoogleVerify;
exports.sendEmailCode = sendEmailCode;
exports.sendForgetEmailCode = sendForgetEmailCode;
exports.sendForgetSmsCode = sendForgetSmsCode;
exports.sendSignEmailCode = sendSignEmailCode;
exports.sendSignEmailCodeGeet = sendSignEmailCodeGeet;
exports.sendSignSmsCode = sendSignSmsCode;
exports.sendSignSmsCodeGeet = sendSignSmsCodeGeet;
exports.sendSmsCode = sendSmsCode;
exports.undataMobile = undataMobile;
exports.updateGoogleVerify = updateGoogleVerify;
exports.updatePassword = updatePassword;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER } = process.env;

function apiGetUserInfo(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Users/getUserInfo`,
    data
  });
}
//获取手机验证码 (非登录态)
function sendSignSmsCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/sendSignSmsCode`,
    data
  });
}
//获取手机验证码 (非登录态-极验)
function sendSignSmsCodeGeet(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Register/mobileCode`,
    data
  });
}
//找回密码，发送短信
function sendForgetSmsCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Forget/sms`,
    data
  });
}

// // 获取手机验证码（二次校验）
// export function loginSendSmsCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendSmsCode`,
//     data,
//   });
// }
//获取手机验证码（二次校验）
function loginSendSmsCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/LoginVerify/mobileCode`,
    data
  });
}

// 获取手机验证码（完全登录态）
function sendSmsCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Users/sendSmsCode`,
    data
  });
}
// 获取邮箱验证码（非登录态）
function sendSignEmailCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Account/sendSignEmailCode`,
    data
  });
}
// 获取邮箱验证码（非登录态-极验）
function sendSignEmailCodeGeet(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Register/emailCode`,
    data
  });
}
// 获取邮箱验证码（非登录态-极验）
function sendForgetEmailCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/Forget/email`,
    data
  });
}

// // 获取邮箱验证码（二次校验）
// export function loginSendEmailCode(data = {}) {
//   return api({
//     url: `${VUE_APP_UCENTER}/UCenter/LoginVerify/sendEmailCode`,
//     data,
//   });
// }
// 获取邮箱验证码（二次校验）
function loginSendEmailCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/V3/UCenter/LoginVerify/emailCode`,
    data
  });
}

// 获取邮箱验证码(完全登录态)
function sendEmailCode(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Users/sendEmailCode`,
    data
  });
}

// 修改密码
function updatePassword(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/updatePassword`,
    data
  });
}

// 绑定手机
function bindMobile(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/bindMobile`,
    data
  });
}

// 绑定邮箱
function bindEmail(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/bindEmail`,
    data
  });
}

// 修改绑定的手机
function undataMobile(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/updateMobile`,
    data
  });
}
// 获取谷歌令牌
function getGoogleSecret(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/getGoogleSecret`,
    data
  });
}

// 绑定谷歌令牌
function bindGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/bindGoogleVerify`,
    data
  });
}

// 修改谷歌令牌
function updateGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/updateGoogleVerify`,
    data
  });
}

// 关闭谷歌认证
function closeGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/closeGoogleVerify`,
    data
  });
}

// 开启谷歌认证
function openGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/openGoogleVerify`,
    data
  });
}

// 获取谷歌绑定状态
function bingGoogleVerifyStatus(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/UCenter/Security/bingGoogleVerifyStatus`,
    data
  });
}

// 根据IP区分国内国外
function checkIp(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Version/checkIp`,
    data
  });
}